import React, { useCallback, useMemo, useState } from 'react';

import { useDispatch } from 'react-redux';
import { Flex } from '@chakra-ui/react';

import {
  DataType,
  Aggregation,
  CompoundAggregation,
  Id,
  Counter,
  QueryParams,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button } from '@m3ter-com/ui-components';

import { PricingUsageEntity } from '@/types/data';

import { addPricingUsageEntities } from '@/store/features/pricing/planDetails';
import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink';
import {
  ColumnDefinition,
  EntityMultiSelectModal,
} from '@/components/common/data/EntitySelectModal';
import {
  AggregationType,
  AggregationPricingListAlert,
} from '@/components/features/pricing/AggregationPricingListAlert';
import { ItemCounterPricingListAlert } from '@/components/features/pricing/ItemCounterPricingListAlert';
import usePlanPricingQueryParams from '@/hooks/features/pricing/usePlanPricingQueryParams';

export interface PlanDetailsPricingGridActionsProps {
  productId?: string;
  selectedAggregations: Array<Aggregation>;
  selectedCompoundAggregations: Array<CompoundAggregation>;
  selectedItemCounters: Array<Counter>;
}

enum ModalType {
  Aggregations = 'AGGREGATIONS',
  CompoundAggregations = 'COMPOUND_AGGREGATIONS',
  ItemCounters = 'ITEM_COUNTERS',
}

const entityModalSearchFields = ['code', 'name'];

const emptyArray = new Array<any>();

export const PlanDetailsPricingGridActions: React.FC<
  PlanDetailsPricingGridActionsProps
> = ({
  productId,
  selectedAggregations,
  selectedCompoundAggregations,
  selectedItemCounters,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    addExtraUsageEntitiesToQueryParams,
    generateUsageEntitiesLinkQueryParams,
  } = usePlanPricingQueryParams();

  const [activeModalType, setActiveModalType] = useState<ModalType | null>(
    null
  );

  const onAddAggregationsClick = useCallback(() => {
    setActiveModalType(ModalType.Aggregations);
  }, []);

  const createAggregationLinkQueryParams = useMemo(
    () => generateUsageEntitiesLinkQueryParams('aggregationId'),
    [generateUsageEntitiesLinkQueryParams]
  );

  const onAddCompoundAggregationsClick = useCallback(() => {
    setActiveModalType(ModalType.CompoundAggregations);
  }, []);

  const createCompoundAggregationLinkQueryParams = useMemo(
    () => generateUsageEntitiesLinkQueryParams('compoundAggregationId'),
    [generateUsageEntitiesLinkQueryParams]
  );

  const onAddItemCountersClick = useCallback(() => {
    setActiveModalType(ModalType.ItemCounters);
  }, []);

  const createItemCounterLinkQueryParams = useMemo(
    () => generateUsageEntitiesLinkQueryParams('counterId'),
    [generateUsageEntitiesLinkQueryParams]
  );

  const onCloseModal = useCallback(() => {
    setActiveModalType(null);
  }, []);
  const onAddAggregations = useCallback(
    (aggregationIds: Array<Id>) => {
      dispatch(addPricingUsageEntities(aggregationIds, false));
      addExtraUsageEntitiesToQueryParams({ aggregationIds });
    },
    [dispatch, addExtraUsageEntitiesToQueryParams]
  );
  const onAddCompoundAggregations = useCallback(
    (compoundAggregationIds: Array<Id>) => {
      dispatch(addPricingUsageEntities(compoundAggregationIds, true));
      addExtraUsageEntitiesToQueryParams({ compoundAggregationIds });
    },
    [dispatch, addExtraUsageEntitiesToQueryParams]
  );
  const onAddItemCounters = useCallback(
    (itemCounterIds: Array<Id>) => {
      dispatch(addPricingUsageEntities(itemCounterIds));
      addExtraUsageEntitiesToQueryParams({ itemCounterIds });
    },
    [dispatch, addExtraUsageEntitiesToQueryParams]
  );
  const isAggregationDisabled = useCallback(
    (item: Aggregation) =>
      selectedAggregations.some((aggregation) => aggregation.id === item.id),
    [selectedAggregations]
  );
  const isCompoundAggregationDisabled = useCallback(
    (item: CompoundAggregation) =>
      selectedCompoundAggregations.some(
        (compoundAggregation) => compoundAggregation.id === item.id
      ),
    [selectedCompoundAggregations]
  );
  const isItemCounterDisabled = useCallback(
    (item: Counter) =>
      selectedItemCounters.some((itemCounter) => itemCounter.id === item.id),
    [selectedItemCounters]
  );
  const entityModalQueryParams = useMemo<QueryParams>(
    () => ({ productId: ['', productId] }), // Matches the product ID or global. Order seems to matter.
    [productId]
  );
  const entityModalColumns = useMemo<
    Array<ColumnDefinition<PricingUsageEntity>>
  >(
    () => [
      { id: 'name', accessor: 'name', header: t('forms:labels.name') },
      { id: 'code', accessor: 'code', header: t('forms:labels.code') },
    ],
    [t]
  );

  return (
    <React.Fragment>
      <Flex gap={4} width="100%" flexDirection="row" flexWrap="wrap">
        <Button onClick={onAddAggregationsClick}>
          {t('forms:buttons.addEntity', {
            entityName: t('common:aggregations'),
          })}
        </Button>
        <Button
          as={CrudCreateLink}
          dataType={DataType.Aggregation}
          queryParams={createAggregationLinkQueryParams}
        >
          {t('forms:buttons.createEntity', {
            entityName: t('common:aggregation'),
          })}
        </Button>
        <Button onClick={onAddCompoundAggregationsClick}>
          {t('forms:buttons.addEntity', {
            entityName: t('common:compoundAggregations'),
          })}
        </Button>
        <Button
          as={CrudCreateLink}
          dataType={DataType.CompoundAggregation}
          queryParams={createCompoundAggregationLinkQueryParams}
        >
          {t('forms:buttons.createEntity', {
            entityName: t('common:compoundAggregation'),
          })}
        </Button>
        <React.Fragment>
          <Button onClick={onAddItemCountersClick}>
            {t('forms:buttons.addEntity', {
              entityName: t('common:counters'),
            })}
          </Button>
          <Button
            as={CrudCreateLink}
            dataType={DataType.Counter}
            queryParams={createItemCounterLinkQueryParams}
          >
            {t('forms:buttons.createEntity', {
              entityName: t('common:counter'),
            })}
          </Button>
        </React.Fragment>
      </Flex>
      <EntityMultiSelectModal<Aggregation>
        columns={entityModalColumns}
        dataType={DataType.Aggregation}
        isItemDisabled={isAggregationDisabled}
        isOpen={activeModalType === ModalType.Aggregations}
        onClose={onCloseModal}
        onConfirm={onAddAggregations}
        params={entityModalQueryParams}
        searchFields={entityModalSearchFields}
        selected={emptyArray}
        listHeader={
          <AggregationPricingListAlert
            aggregationType={AggregationType.Aggregations}
          />
        }
      />
      <EntityMultiSelectModal<CompoundAggregation>
        columns={entityModalColumns}
        dataType={DataType.CompoundAggregation}
        isItemDisabled={isCompoundAggregationDisabled}
        isOpen={activeModalType === ModalType.CompoundAggregations}
        onClose={onCloseModal}
        onConfirm={onAddCompoundAggregations}
        params={entityModalQueryParams}
        searchFields={entityModalSearchFields}
        selected={emptyArray}
        listHeader={
          <AggregationPricingListAlert
            aggregationType={AggregationType.CompoundAggregations}
          />
        }
      />
      <EntityMultiSelectModal<Counter>
        columns={entityModalColumns}
        dataType={DataType.Counter}
        isItemDisabled={isItemCounterDisabled}
        isOpen={activeModalType === ModalType.ItemCounters}
        onClose={onCloseModal}
        onConfirm={onAddItemCounters}
        params={entityModalQueryParams}
        searchFields={entityModalSearchFields}
        selected={emptyArray}
        listHeader={<ItemCounterPricingListAlert />}
      />
    </React.Fragment>
  );
};
