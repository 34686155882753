import React from 'react';

import { Spinner, VStack } from '@chakra-ui/react';
import { Trans } from 'react-i18next';

import { DataType, AccountPlan } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Alert, AlertLink } from '@m3ter-com/ui-components';

import type { TFunction } from 'i18next';

import usePlanDetails from '@/hooks/features/plans/usePlanDetails';
import { AccountPlanDetails } from '@/components/features/accounts/AccountPlanDetails';
import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert';
import { PlanDetails } from '@/components/features/pricing/PlanDetails';
import { PlanDetailsPricingGrid } from '@/components/features/pricing/PlanDetailsPricingGrid';
import { PlanGroupDetails } from '@/components/features/pricing/PlanGroupDetails';
import { PlanGroupPlanDetailsAndPricing } from '@/components/features/pricing/PlanGroupPlanDetailsAndPricing/PlanGroupPlanDetailsAndPricing';

interface AccountPlanOverviewProps {
  data: AccountPlan;
}

export const AccountPlanOverview: React.FC<AccountPlanOverviewProps> = ({
  data,
}) => {
  const { t } = useTranslation();
  const {
    error,
    extraPricingAggregations,
    extraPricingCompoundAggregations,
    extraPricingItemCounters,
    isLoading,
    isLoadingExtraUsageEntities,
    pricingData,
    onAddPlansToPlanGroup,
  } = usePlanDetails(undefined, undefined, data.id);

  if (isLoading || !pricingData) {
    return <Spinner />;
  }

  const isPlanGroupPage = !!pricingData.planGroup;
  const isPlanGroupCustom =
    isPlanGroupPage && !!pricingData.planGroup!.accountId;
  const isPlanPage =
    !isPlanGroupPage &&
    pricingData.plans.length === 1 &&
    pricingData.planTemplates.length === 1;
  const isPlanCustom = isPlanPage && !!pricingData.plans[0].accountId;

  return (
    <VStack spacing={4} flex={2} alignItems="stretch">
      {/* There isn't a title for an account-plan so the retrieve route won't have added a breadcrumb. */}
      <BreadcrumbItem
        title={
          isPlanGroupPage
            ? t('features:planGroups.planGroupAttachment')
            : t('features:plans.planAttachment')
        }
      />
      {!!error && <ErrorAlert error={error} />}
      <AccountPlanDetails accountPlan={data} />
      {!isPlanCustom && !isPlanGroupCustom && (
        <Alert status="info">
          <Trans
            t={t as TFunction}
            i18nKey={
              isPlanPage
                ? 'features:account.customPlanEditWarning'
                : 'features:account.customPlanGroupEditWarning'
            }
            components={{
              planDetailsLink: (
                <AlertLink
                  as={CrudDetailsLink}
                  dataType={isPlanPage ? DataType.Plan : DataType.PlanGroup}
                  id={
                    isPlanPage
                      ? pricingData.plans[0].id
                      : pricingData.planGroup!.id
                  }
                />
              ),
            }}
          />
        </Alert>
      )}
      {isPlanPage && (
        <React.Fragment>
          <PlanDetails
            plan={pricingData.plans[0]}
            planTemplate={pricingData.planTemplates[0]}
            showEditButton={isPlanCustom}
            showIsCustomDetails
          />
          <PlanDetailsPricingGrid
            canEditPricing={isPlanCustom}
            canEditTemplatePricing={false}
            isLoading={isLoading || isLoadingExtraUsageEntities}
            extraPricingAggregations={extraPricingAggregations}
            extraPricingCompoundAggregations={extraPricingCompoundAggregations}
            extraPricingItemCounters={extraPricingItemCounters}
            pricingData={pricingData}
          />
        </React.Fragment>
      )}
      {isPlanGroupPage && (
        <React.Fragment>
          <PlanGroupDetails
            planGroup={pricingData.planGroup!}
            showEditButton={isPlanGroupCustom}
            showIsCustomDetails
          />
          <PlanGroupPlanDetailsAndPricing
            accountId={data.accountId}
            onAddPlansToPlanGroup={onAddPlansToPlanGroup}
            extraPricingAggregations={extraPricingAggregations}
            extraPricingCompoundAggregations={extraPricingCompoundAggregations}
            extraPricingItemCounters={extraPricingItemCounters}
            isPlanGroupCustom={isPlanGroupCustom}
            isLoading={isLoading || isLoadingExtraUsageEntities}
            pricingData={pricingData}
          />
        </React.Fragment>
      )}
    </VStack>
  );
};
