import {
  IntegrationConfig,
  IntegrationRun,
  IntegrationRunLogEntry,
  IntegrationRunLogEntryType,
  IntegrationRunLogHttpEntry,
  IntegrationRunLogMessageEntry,
} from '@m3ter-com/m3ter-api';

export const integrationAccessor = (integration: IntegrationConfig) =>
  integration.name || '-';

export const integrationDetailsAccessor = (integration: IntegrationConfig) =>
  `${integration.entityType} - ${integration.destination}`;

export const integrationRunAccessor = (integrationRun: IntegrationRun) => {
  let result = integrationRun.entityType;
  if (integrationRun.entityId) {
    result += ` (${integrationRun.entityId})`;
  }
  result += ` - ${integrationRun.destination}`;
  return result;
};

export const isIntegrationRunLogHttpEntry = (
  runLogEntry: IntegrationRunLogEntry
): runLogEntry is IntegrationRunLogHttpEntry =>
  runLogEntry.type === IntegrationRunLogEntryType.Http;

export const isIntegrationRunLogMessageEntry = (
  runLogEntry: IntegrationRunLogEntry
): runLogEntry is IntegrationRunLogMessageEntry =>
  runLogEntry.type === IntegrationRunLogEntryType.Message;
