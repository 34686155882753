import {
  DefaultError,
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { AppError } from '@/types/errors';

import { extractError } from '@/util/error';

// Wrapper around RQ's `useQuery` to extract errors into `AppError`s.

export interface UseAppQueryResult<TData = unknown, TError = DefaultError>
  extends Omit<UseQueryResult<TData, TError>, 'error'> {
  error: AppError | undefined;
}

const useAppQuery = <
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
): UseAppQueryResult<TData, TError> => {
  const result = useQuery(options);

  return {
    ...result,
    error: result.error ? extractError(result.error) : undefined,
  };
};

export default useAppQuery;
