import { useMemo } from 'react';

import {
  DataType,
  ExternalMappingEntityType,
  externalMappingEntityTypeToDataType,
  ExternalMappingEntityTypeToEntity,
} from '@m3ter-com/m3ter-api';
import { ComboboxOption } from '@m3ter-com/ui-components';

import { Accessor, getAccessorValue } from '@/util/data';
import { dataTypeListAllQuery } from '@/queries/crud';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import useAppQuery, { UseAppQueryResult } from '@/hooks/data/useAppQuery';

// For some external mapping entity types, we can load the m3ter entities that can be selected so that the user
// can select them from a dropdown with proper labels, instead of needing to type/paste an ID into a text input.
//
// To do this, we need to know which ExternalMappingEntityType we can load data for, so we need to know the
// corresponding DataType.
// We also need to know the accessors for that DataType so we can build the select options.
// We need to know the mappings that have already been created for the external system and table because a m3ter
// entity can't be mapped to the same external system & table more than once.
// And, we need to know what the initially selected ID was so that we can include that entity as an option on
// edit forms.

type ExternalMappingEntityTypeAccessor<E extends ExternalMappingEntityType> =
  Accessor<ExternalMappingEntityTypeToEntity<E>, string>;
type ExternalMappingEntityTypeAccessorsMap = {
  [E in ExternalMappingEntityType]?: {
    primary: ExternalMappingEntityTypeAccessor<E>;
    details?: ExternalMappingEntityTypeAccessor<E>;
  };
};
const externalMappingEntityTypeAccessorsMap: ExternalMappingEntityTypeAccessorsMap =
  {
    [ExternalMappingEntityType.Account]: {
      primary: 'name',
      details: 'code',
    },
    [ExternalMappingEntityType.Aggregation]: {
      primary: 'name',
      details: 'code',
    },
    [ExternalMappingEntityType.CompoundAggregation]: {
      primary: 'name',
      details: 'code',
    },
    [ExternalMappingEntityType.Contract]: {
      primary: 'name',
    },
    [ExternalMappingEntityType.Organization]: {
      primary: 'organizationName',
    },
    [ExternalMappingEntityType.Meter]: {
      primary: 'name',
      details: 'code',
    },
    [ExternalMappingEntityType.Plan]: {
      primary: 'name',
      details: 'code',
    },
    [ExternalMappingEntityType.Product]: {
      primary: 'name',
      details: 'code',
    },
  };

const useExternalMappingM3terIdField = <E extends ExternalMappingEntityType>(
  entityType?: E,
  externalSystem?: string,
  externalTable?: string,
  initialEntityId?: string
) => {
  const dataType = entityType
    ? externalMappingEntityTypeToDataType[entityType]
    : undefined;
  const accessors = entityType
    ? externalMappingEntityTypeAccessorsMap[entityType]
    : undefined;
  const canGetOptions =
    !!entityType &&
    !!externalSystem &&
    !!externalTable &&
    !!dataType &&
    !!accessors;

  const pathParams = useOrgPathParams();

  const {
    data: allEntities,
    error: allEntitiesError,
    isLoading: isLoadingAllEntities,
  } = useAppQuery(
    dataTypeListAllQuery(
      { dataType: dataType!, pathParams },
      { enabled: canGetOptions }
    )
  ) as UseAppQueryResult<Array<ExternalMappingEntityTypeToEntity<E>>>;

  const existingSystemMappingsQueryParams = useMemo(
    () => ({
      externalSystemId: externalSystem,
      m3terEntity: entityType,
    }),
    [entityType, externalSystem]
  );
  const {
    data: existingSystemMappings,
    isLoading: isLoadingExistingSystemMappings,
    error: existingSystemMappingsError,
  } = useAppQuery(
    dataTypeListAllQuery(
      {
        dataType: DataType.ExternalMapping,
        pathParams,
        queryParams: existingSystemMappingsQueryParams,
      },
      {
        enabled: canGetOptions,
      }
    )
  );

  const options = useMemo<Array<ComboboxOption>>(() => {
    if (!canGetOptions || !allEntities?.length || !existingSystemMappings) {
      return [];
    }

    const mappedEntityIds = existingSystemMappings
      .filter((mapping) => mapping.externalTable === externalTable)
      .map((mapping) => mapping.m3terId);
    const selectableEntities = allEntities.filter((entity) => {
      if (initialEntityId && entity.id === initialEntityId) {
        return true;
      }

      return !mappedEntityIds.includes(entity.id);
    });

    return selectableEntities.map((entity) => ({
      value: entity.id,
      label: getAccessorValue(entity, accessors.primary),
      secondaryLabel: accessors.details
        ? getAccessorValue(entity, accessors.details)
        : undefined,
    }));
  }, [
    accessors,
    allEntities,
    canGetOptions,
    existingSystemMappings,
    externalTable,
    initialEntityId,
  ]);

  return {
    error: allEntitiesError || existingSystemMappingsError,
    isLoading: isLoadingAllEntities || isLoadingExistingSystemMappings,
    options,
  };
};

export default useExternalMappingM3terIdField;
