/* eslint-disable react/destructuring-assignment */

/**
 * If we destructure the props for this component, we lose the type inference
 * for some of the props.
 * For example, whether the component expects selectedItemId or selectedItemIds or neither
 * depends on whether the rowSelectionMode prop is passed as "single", "multi" or undefined
 * / omitted.
 * If we destructure the props in the FC args, selectedItemId will be typed as
 * `string | null | undefined` and selectedItemIds will be typed as
 * `Array<string> | undefined` and typescript will no longer be able to
 * tell that selectedItemId should not be undefined if we check for rowSelectionMode==="single".
 * This is a known typescript behaviour, see https://github.com/microsoft/TypeScript/issues/27497
 */
import React, { PropsWithChildren, ReactElement, ReactNode } from 'react';

import {
  Box,
  useMultiStyleConfig,
  type StyleProps,
  type TableProps,
  type TableRowProps,
  type TableCellProps,
  VStack,
} from '@chakra-ui/react';

import { Pagination } from '../../controls/Pagination';
import { DataTable } from '../DataTable';

import useInteractiveTable, {
  type InteractiveColumnDefinition,
  type InteractiveRowItem,
} from './useInteractiveTable';

// We export the interfaces that make up the full props so that consumers
// can use them to construct the props for their own HOCs more easily.

export { InteractiveColumnDefinition, InteractiveRowItem };

export interface InteractiveTableBaseProps<
  D extends InteractiveRowItem = InteractiveRowItem
> {
  columnDefinitions: Array<InteractiveColumnDefinition<D>>;
  items: Array<D>;
  footer?: ReactNode;
  isLoading?: boolean;
  isItemSelectable?: string | ((item: D) => boolean);
  emptyContent?: ReactNode;
  enableColumnOrdering?: boolean;
  enableColumnToggling?: boolean;
  loadingRows?: number;
  styleProps?: StyleProps;
  tableCellStyleProps?: TableCellProps;
  tableRowStyleProps?: TableRowProps;
  tableStyleProps?: TableProps;
}

export interface InteractiveTableWithSingleRowSelectionProps {
  rowSelectionMode: 'single';
  selectedItemId: string | null;
  selectedItemIds?: Array<string>;
  onSelectedItemChange: (itemId: string | null) => void;
  onSelectedItemsChange?: (itemIds: Array<string>) => void;
}
export interface InteractiveTableWithMultiRowSelectionProps {
  rowSelectionMode: 'multi';
  selectedItemId?: string | null;
  selectedItemIds: Array<string>;
  onSelectedItemChange?: (itemId: string | null) => void;
  onSelectedItemsChange: (itemIds: Array<string>) => void;
}
interface InteractiveTableWithoutRowSelectionProps {
  rowSelectionMode?: undefined;
  selectedItemId?: never;
  selectedItemIds?: never;
  onSelectedItemChange?: never;
  onSelectedItemsChange?: never;
}
export type InteractiveTableRowSelectionProps =
  | InteractiveTableWithMultiRowSelectionProps
  | InteractiveTableWithSingleRowSelectionProps
  | InteractiveTableWithoutRowSelectionProps;

export interface InteractiveTableClientOrNoPaginationProps {
  currentPage?: never;
  hasMorePages?: never;
  pageCount?: never;
  paginationDisabled?: boolean;
  paginationType?: 'client';
  onNextPage?: never;
  onPageChange?: never;
}
export interface InteractiveTableServerPaginationProps {
  currentPage: number;
  hasMorePages: boolean;
  pageCount: number;
  paginationDisabled: boolean;
  paginationType: 'server';
  onNextPage: () => void;
  onPageChange: (pageNumber: number) => void;
}
export type InteractiveTablePaginationProps =
  | InteractiveTableClientOrNoPaginationProps
  | InteractiveTableServerPaginationProps;

export type InteractiveTableProps<
  D extends InteractiveRowItem = InteractiveRowItem
> = InteractiveTableBaseProps<D> &
  InteractiveTableRowSelectionProps &
  InteractiveTablePaginationProps;

export function InteractiveTable<
  D extends InteractiveRowItem = InteractiveRowItem
>(
  props: PropsWithChildren<InteractiveTableProps<D>>
): ReactElement<any, any> | null {
  const { dataTableProps, clientPaginationProps } = useInteractiveTable<D>(
    props.columnDefinitions,
    props.items,
    props.paginationType,
    props.rowSelectionMode,
    props.selectedItemId,
    props.onSelectedItemChange,
    props.selectedItemIds,
    props.onSelectedItemsChange,
    props.isItemSelectable
  );

  const styles = useMultiStyleConfig('InteractiveTable', {});

  return (
    <VStack alignItems="stretch">
      <Box {...props.styleProps}>
        <DataTable
          {...dataTableProps}
          isLoading={props.isLoading}
          emptyContent={props.emptyContent}
          enableColumnToggling={props.enableColumnToggling}
          enableColumnOrdering={props.enableColumnOrdering}
        />
      </Box>
      <Box sx={styles.footerContainer}>
        {props.footer}
        {!!props.paginationType && (
          <Box
            sx={styles.paginationContainer}
            data-testid="interactive-table-pagination-container"
          >
            {props.paginationType === 'server' ? (
              <Pagination
                currentPage={props.currentPage}
                isDisabled={props.paginationDisabled}
                hasMore={props.hasMorePages}
                pageCount={props.pageCount}
                onChange={props.onPageChange}
                onNext={props.onNextPage}
              />
            ) : (
              <Pagination {...clientPaginationProps} />
            )}
          </Box>
        )}
      </Box>
    </VStack>
  );
}
