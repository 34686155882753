import { useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { DataType, DataTypeToEntity, Id } from '@m3ter-com/m3ter-api';

import {
  addToRecentEntities,
  selectRecentEntityIdsByDataType,
} from '@/store/page/page';
import { dataTypeListQuery } from '@/queries/crud';
import useAppQuery from '@/hooks/data/useAppQuery';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';

const useRecentlyViewed = <DT extends DataType>(
  dataType: DT,
  loadRecentlyViewed = false
) => {
  const dispatch = useDispatch();
  const addToRecentlyViewed = useCallback(
    (id: Id) => {
      dispatch(addToRecentEntities(dataType, id));
    },
    [dispatch, dataType]
  );

  const recentEntityIdsSelector = useMemo(
    () => selectRecentEntityIdsByDataType(dataType),
    [dataType]
  );
  const pathParams = useOrgPathParams();
  const recentEntityIds = useSelector(recentEntityIdsSelector);
  const {
    data: recentlyViewed = [],
    error: loadingRecentlyViewedError,
    isLoading: isLoadingRecentlyViewed,
  } = useAppQuery(
    dataTypeListQuery(
      {
        dataType,
        pathParams,
        queryParams: { ids: recentEntityIds },
      },
      {
        enabled: recentEntityIds.length > 0 && loadRecentlyViewed,
        keepPreviousData: true,
      }
    )
  );

  return {
    addToRecentlyViewed,
    isLoadingRecentlyViewed,
    lastViewed: recentlyViewed[0] as DataTypeToEntity[DT] | undefined,
    loadingRecentlyViewedError,
    recentlyViewed,
  };
};

export default useRecentlyViewed;
