import React, { ReactElement, useCallback } from 'react';

import { Box, Icon, TableColumnHeaderProps, Th } from '@chakra-ui/react';

import { SortDownIcon, SortIcon, SortUpIcon } from '../../icons';

import { ColumnDefinition, SortProps } from './types';

export interface DataTableColumnHeaderProps<D>
  extends SortProps,
    TableColumnHeaderProps {
  columnDefinition: ColumnDefinition<D>;
}

export function DataTableColumnHeader<D>({
  columnDefinition,
  sortColumn,
  sortDescending,
  onSortChange,
  ...thProps
}: DataTableColumnHeaderProps<D>): ReactElement<any, any> | null {
  const isSortedColumn = columnDefinition.id === sortColumn;

  let sortIcon;
  if (columnDefinition.isSortable) {
    if (isSortedColumn) {
      sortIcon = sortDescending ? SortDownIcon : SortUpIcon;
    } else {
      sortIcon = SortIcon;
    }
  }

  const onClick = useCallback(() => {
    if (onSortChange && columnDefinition.isSortable) {
      if (isSortedColumn) {
        // Toggle the direction
        onSortChange(columnDefinition.id, !sortDescending);
      } else {
        onSortChange(columnDefinition.id, false);
      }
    }
  }, [onSortChange, columnDefinition, isSortedColumn, sortDescending]);

  return (
    <Th
      {...thProps}
      textAlign={columnDefinition.align}
      onClick={onClick}
      userSelect="none"
    >
      <Box
        role={columnDefinition.isSortable ? 'button' : undefined}
        cursor={columnDefinition.isSortable ? 'pointer' : undefined}
      >
        {columnDefinition.header}
        {sortIcon && (
          <Icon
            as={sortIcon}
            verticalAlign="text-bottom"
            ml={2}
            color={isSortedColumn ? 'misc-highlight' : undefined}
            data-testid={
              isSortedColumn
                ? `sort-${sortDescending ? 'desc' : 'asc'}`
                : 'sortable'
            }
          />
        )}
      </Box>
    </Th>
  );
}
