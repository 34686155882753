import React, { useMemo } from 'react';

import {
  Box,
  Card,
  CardBody,
  Grid,
  useDisclosure,
  ButtonGroup,
  VStack,
  Heading,
} from '@chakra-ui/react';

import { DataType, Account } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button, CardActionsHeader } from '@m3ter-com/ui-components';

import useNestedSubRoute from '@/hooks/util/useNestedSubRoute';
import useEndBillingEntities from '@/hooks/features/accounts/useEndBillingEntities';
import { Tab, TabbedRoute } from '@/components/common/navigation/TabbedRoute';
import { useCrudRetrieveData } from '@/components/common/crud/CrudRetrieveContext';
import { CrudEditLink } from '@/components/common/navigation/CrudEditLink';
import { AccountDetails } from '@/components/features/accounts/AccountDetails';
import { AccountIngestModal } from '@/components/features/accounts/AccountIngestModal';
import { AccountOverviewActions } from '@/components/features/accounts/AccountOverviewActions';
import { EndBillingEntitiesModal } from '@/components/features/accounts/EndBillingEntitiesModal';
import useAccountHasChildren from '@/hooks/features/accounts/useAccountHasChildren';

export interface AccountsRetrieveRouteProps {}

export const AccountsRetrieveRoute: React.FC<
  AccountsRetrieveRouteProps
> = () => {
  const { t } = useTranslation();
  const { isNestedSubRoute } = useNestedSubRoute();
  const account = useCrudRetrieveData<Account>(DataType.Account);

  const { hasChildren: isParentAccount } = useAccountHasChildren(account.id);

  const {
    onEndBillingEntities,
    isEndBillingEntitiesModalOpen,
    onOpenEndBillingEntitiesModal,
    onCloseEndBillingEntitiesModal,
  } = useEndBillingEntities(account.id, DataType.Account);

  const {
    isOpen: isIngestModalOpen,
    onOpen: onOpenIngestModal,
    onClose: onCloseIngestModal,
  } = useDisclosure();

  const tabs = useMemo<Array<Tab>>(
    () => [
      { to: '', text: t('common:overview') },
      ...(!account.parentAccountId
        ? [{ to: 'children', text: t('features:account.childAccounts') }]
        : []),
      { to: 'bills', text: t('common:bills') },
      { to: 'commitments', text: t('common:commitments') },
      { to: 'balances', text: t('common:balances') },
      { to: 'plans', text: t('features:account.attachedPlans') },
      { to: 'contracts', text: t('common:contracts') },
      { to: 'external-mappings', text: t('common:externalMappings') },
      {
        to: 'counter-adjustments',
        text: t('common:counterAdjustments'),
      },
    ],
    [t, account]
  );

  return (
    <React.Fragment>
      <Grid gap={4} templateColumns={!isNestedSubRoute ? '75% 1fr' : '100%'}>
        <Box>
          <TabbedRoute tabs={tabs} />
        </Box>
        {!isNestedSubRoute && (
          <VStack spacing={4} alignItems="stretch">
            <ButtonGroup justifyContent="flex-end">
              <Button onClick={onOpenIngestModal}>
                {t('features:ingest.submitUsage')}
              </Button>
              <AccountOverviewActions
                accountId={account.id}
                onOpenIngestModal={onOpenIngestModal}
                onOpenEndBillingEntitiesModal={onOpenEndBillingEntitiesModal}
              />
            </ButtonGroup>
            <Card>
              <CardActionsHeader
                actions={
                  <ButtonGroup>
                    <Button
                      size="sm"
                      intent="primary"
                      as={CrudEditLink}
                      addReturnPath
                      dataType={DataType.Account}
                      id={account.id}
                    >
                      {t('common:edit')}
                    </Button>
                  </ButtonGroup>
                }
              >
                <Heading size="md">{t('features:account.details')}</Heading>
              </CardActionsHeader>
              <CardBody>
                <AccountDetails account={account} />
              </CardBody>
            </Card>
          </VStack>
        )}
      </Grid>
      <AccountIngestModal
        accountCode={account.code}
        isOpen={isIngestModalOpen}
        onClose={onCloseIngestModal}
      />
      <EndBillingEntitiesModal
        dataType={DataType.Account}
        showApplyToChildren={isParentAccount}
        isOpen={isEndBillingEntitiesModalOpen}
        onClose={onCloseEndBillingEntitiesModal}
        onSubmit={onEndBillingEntities}
      />
    </React.Fragment>
  );
};
