import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Box,
  ButtonGroup,
  Flex,
  Icon,
  List,
  ListItem,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Portal,
  Switch,
  useDisclosure,
} from '@chakra-ui/react';
import { Reorder, useDragControls } from 'framer-motion';
import { Columns3Icon, GripVerticalIcon } from 'lucide-react';

import { Button, IconButton } from '../../controls/Button';

import {
  ColumnOption,
  ColumnOptionWithVisibility,
  ColumnDisplay,
  DataTableTranslations,
} from './types';
import { getSortedColumnOptionsWithVisibility } from './utils';

export interface DataTableColumnSettingsProps {
  columnOptions: Array<ColumnOption>;
  translations: DataTableTranslations;
  value?: Array<ColumnDisplay>;
  enableOrdering?: boolean;
  enableToggling?: boolean;
  onChange?: (columnDisplay: Array<ColumnDisplay>) => void;
}

interface ColumnItemProps {
  option: ColumnOptionWithVisibility;
  enableOrdering: boolean;
  enableToggling: boolean;
  onToggle: () => void;
  isToggleDisabled?: boolean;
}

const ColumnItem: React.FC<ColumnItemProps> = ({
  option,
  enableOrdering,
  enableToggling,
  isToggleDisabled = false,
  onToggle,
}) => {
  const controls = useDragControls();
  const id = `toggle-${option.id}`;

  return (
    <ListItem
      as={Reorder.Item}
      value={option as any} // `MergeWithAs` is overriding `Reorder.Item`'s `value` prop with one from React's `LiHTMLAttributes` so we get a TS error without the cast.
      dragListener={false}
      dragControls={controls}
      userSelect="none"
    >
      <Flex gap={2} alignItems="center">
        {enableOrdering && (
          <Icon
            as={GripVerticalIcon}
            onPointerDown={(e) => controls.start(e)}
            color="chakra-subtle-text"
            cursor="move"
          />
        )}
        {enableToggling ? (
          <Box as="label" flex={1} htmlFor={id}>
            {option.header}
          </Box>
        ) : (
          <Box flex={1}>{option.header}</Box>
        )}
        {enableToggling && (
          <Switch
            id={id}
            size="sm"
            isChecked={option.visible}
            onChange={onToggle}
            isDisabled={isToggleDisabled}
            value={option.id}
          />
        )}
      </Flex>
    </ListItem>
  );
};

export const DataTableColumnSettings: React.FC<
  DataTableColumnSettingsProps
> = ({
  columnOptions,
  value,
  enableOrdering = false,
  enableToggling = false,
  translations,
  onChange,
}) => {
  const defaultValue = useMemo(
    () =>
      columnOptions.map((columnOption) => ({
        id: columnOption.id,
        visible: true,
      })),
    [columnOptions]
  );

  const { isOpen, onToggle, onClose } = useDisclosure();

  const [sortedColumnOptions, setSortedColumnOptions] = useState<
    Array<ColumnOptionWithVisibility>
  >(getSortedColumnOptionsWithVisibility(columnOptions, value ?? defaultValue));

  const resetOptions = useCallback(() => {
    setSortedColumnOptions(
      getSortedColumnOptionsWithVisibility(columnOptions, value ?? defaultValue)
    );
  }, [columnOptions, value, defaultValue]);

  // Update the options if the value or options are updated.
  useEffect(() => {
    resetOptions();
  }, [resetOptions, columnOptions, value]);

  const onToggleOption = useCallback((option: ColumnOptionWithVisibility) => {
    setSortedColumnOptions((currentValue) =>
      currentValue.map((item) =>
        item === option ? { ...item, visible: !item.visible } : item
      )
    );
  }, []);

  const visibleColumnCount = useMemo(
    () => sortedColumnOptions.filter((option) => option.visible).length,
    [sortedColumnOptions]
  );

  const onCancel = useCallback(() => {
    onClose();
    resetOptions();
  }, [onClose, resetOptions]);

  const onConfirm = useCallback(() => {
    if (onChange) {
      onChange(sortedColumnOptions.map(({ id, visible }) => ({ id, visible })));
    }
    onClose();
  }, [sortedColumnOptions, onChange, onClose]);

  return (
    <Popover isOpen={isOpen} placement="bottom-end">
      <PopoverTrigger>
        <IconButton
          size="xs"
          icon={<Columns3Icon size={16} />}
          verticalAlign="middle"
          onClick={onToggle}
          aria-label={translations.columnSettings}
        />
      </PopoverTrigger>
      {/* Portal prevents the menu rendering in the table header and inheriting its styles. */}
      <Portal>
        <PopoverContent>
          <PopoverBody>
            <List
              as={Reorder.Group}
              axis="y"
              values={sortedColumnOptions}
              onReorder={setSortedColumnOptions}
              spacing={2}
            >
              {sortedColumnOptions.map((columnOption) => (
                <ColumnItem
                  key={columnOption.id}
                  option={columnOption}
                  enableOrdering={enableOrdering}
                  enableToggling={enableToggling}
                  isToggleDisabled={
                    // Disble the toggle if this is the last visible option.
                    visibleColumnCount === 1 && columnOption.visible
                  }
                  onToggle={() => onToggleOption(columnOption)}
                />
              ))}
            </List>
          </PopoverBody>
          <PopoverFooter>
            <ButtonGroup size="sm">
              <Button onClick={onConfirm}>{translations.update}</Button>
              <Button variant="ghost" onClick={onCancel}>
                {translations.cancel}
              </Button>
            </ButtonGroup>
          </PopoverFooter>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
