import React, { ReactNode, ReactElement, PropsWithChildren } from 'react';

import { Icon, Spinner, Tooltip } from '@chakra-ui/react';
import { TriangleAlert } from 'lucide-react';

import { DataType, DataTypeToEntity } from '@m3ter-com/m3ter-api';

import { Accessor, getAccessorValue } from '@/util/data';
import { dataTypeRetrieveQuery } from '@/queries/crud';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import useAppQuery from '@/hooks/data/useAppQuery';

export interface ReferenceLabelProps<DT extends DataType> {
  dataType: DT;
  id: string;
  accessor: Accessor<DataTypeToEntity[DT], ReactNode>;
}

export function ReferenceLabel<DT extends DataType>({
  dataType,
  id,
  accessor,
}: PropsWithChildren<ReferenceLabelProps<DT>>): ReactElement<any, any> | null {
  const pathParams = useOrgPathParams();
  const { isLoading, error, data } = useAppQuery(
    dataTypeRetrieveQuery({
      dataType,
      id,
      pathParams,
    })
  );

  if (isLoading) {
    return (
      <Spinner
        data-testid="reference-label-spinner"
        color="chakra-body-text"
        size="sm"
      />
    );
  }

  if (error) {
    return (
      <Tooltip hasArrow label="There was a problem loading data">
        <span>
          <Icon
            data-testid="reference-label-error-tooltip"
            as={TriangleAlert}
          />
        </span>
      </Tooltip>
    );
  }

  if (!data) return null;

  return (
    <span data-testid="reference-label-accessor">
      {getAccessorValue(data, accessor)}
    </span>
  );
}
