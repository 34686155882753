import React, { useMemo } from 'react';

import { Card, CardBody, Heading, Link } from '@chakra-ui/react';

import { DataType, Id, User } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  Button,
  CardActionsHeader,
  Confirmation,
} from '@m3ter-com/ui-components';

import { OtherListIds } from '@/types/lists';

import {
  ColumnDefinition,
  CrudList,
  CrudListFooter,
  CrudListHeader,
  CrudListTable,
} from '@/components/common/crud/CrudList';
import { ActiveStatusBadge } from '@/components/common/data/ActiveStatusBadge';
import { EntityMultiSelectModal } from '@/components/common/data/EntitySelectModal';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert';
import useUserGroupManagement from '@/hooks/features/access/useUserGroupManagement';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink';

export interface UserGroupUsersListProps {
  userGroupId: Id;
}

const addUsersListSearchFields = ['firstName', 'lastName'];

const emptyArray = new Array<any>();

export const UserGroupUsersList: React.FC<UserGroupUsersListProps> = ({
  userGroupId,
}) => {
  const { t } = useTranslation();

  const {
    errorLoadingUserGroupItems,
    isAddingUsersToUserGroup,
    isRemovingUsersFromUserGroup,
    isUserDisabled,
    isUsersModalOpen,
    onAddUsersToUserGroup,
    onCloseUsersModal,
    onOpenUsersModal,
    onRemoveUsersFromUserGroup,
    selectedUserIds,
    setSelectedUserIds,
    userGroupItems,
  } = useUserGroupManagement(userGroupId);

  const userListQueryParams = useMemo(
    () => ({ ids: userGroupItems.map(({ targetId }) => targetId) }),
    [userGroupItems]
  );

  const nameColumnDefinition = useMemo<
    Omit<ColumnDefinition<User>, 'accessor'>
  >(
    () => ({
      id: 'name',
      header: t('forms:labels.name'),
    }),
    [t]
  );

  const statusColumnDefinition = useMemo<ColumnDefinition<User>>(
    () => ({
      id: 'user-status',
      header: t('forms:labels.status'),
      accessor: ({ dtEndAccess }: User) => (
        <ActiveStatusBadge
          ml={0}
          active={!dtEndAccess || new Date(dtEndAccess) > new Date(Date.now())}
        />
      ),
    }),
    [t]
  );

  const userColumnDefinitions = useMemo<Array<ColumnDefinition<User>>>(
    () => [
      {
        ...nameColumnDefinition,
        accessor: ({ firstName, lastName, id }: User) => (
          <Link as={CrudDetailsLink} dataType={DataType.User} id={id}>
            {`${firstName} ${lastName}`}
          </Link>
        ),
      },
      statusColumnDefinition,
    ],
    [nameColumnDefinition, statusColumnDefinition]
  );

  const userModalColumnDefinitions = useMemo<Array<ColumnDefinition<User>>>(
    () => [
      {
        ...nameColumnDefinition,
        accessor: ({ firstName, lastName }: User) => `${firstName} ${lastName}`,
      },
      statusColumnDefinition,
    ],
    [nameColumnDefinition, statusColumnDefinition]
  );

  return (
    <React.Fragment>
      {errorLoadingUserGroupItems && (
        <ErrorAlert error={errorLoadingUserGroupItems} />
      )}
      <Card>
        <CardActionsHeader
          actions={
            <Button
              size="sm"
              onClick={onOpenUsersModal}
              isDisabled={isRemovingUsersFromUserGroup}
              isLoading={isAddingUsersToUserGroup}
            >
              {t('forms:buttons.addEntity', {
                entityName: t('common:users'),
              })}
            </Button>
          }
        >
          <Heading size="md">{t('common:users')}</Heading>
        </CardActionsHeader>
        <CardBody>
          <CrudList<User>
            dataType={DataType.User}
            listId={OtherListIds.UserGroupUsers}
            params={userListQueryParams}
          >
            <CrudListHeader hideCreateLink />
            <CrudListTable
              columns={userColumnDefinitions}
              selectionType="multi"
              selectedItems={selectedUserIds}
              onSelectedItemsChange={setSelectedUserIds}
            />
            <CrudListFooter>
              <Confirmation
                trigger={
                  <Button
                    isDisabled={
                      selectedUserIds.length === 0 || isAddingUsersToUserGroup
                    }
                    isLoading={isRemovingUsersFromUserGroup}
                  >
                    {t('common:removeSelected')}
                  </Button>
                }
                message={t('features:access.removeFromUserGroupConfirmation')}
                onConfirm={onRemoveUsersFromUserGroup}
              />
            </CrudListFooter>
          </CrudList>
        </CardBody>
      </Card>
      <EntityMultiSelectModal<User>
        columns={userModalColumnDefinitions}
        dataType={DataType.User}
        isOpen={isUsersModalOpen}
        onClose={onCloseUsersModal}
        onConfirm={onAddUsersToUserGroup}
        searchFields={addUsersListSearchFields}
        isItemDisabled={isUserDisabled}
        selected={emptyArray}
      />
    </React.Fragment>
  );
};
