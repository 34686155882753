import React, { useMemo } from 'react';

import { useLocation } from 'react-router-dom';
import { Card, CardBody, Heading } from '@chakra-ui/react';

import {
  DataType,
  Account,
  ExternalMapping,
  ExternalMappingEntityType,
  PathParams,
  QueryParams,
} from '@m3ter-com/m3ter-api';
import { Button, CardActionsHeader } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { OtherListIds } from '@/types/lists';

import useEntityDelete from '@/hooks/data/crud/useEntityDelete';
import useCrudRouteNames from '@/hooks/navigation/useCrudRouteNames';
import { useCrudRetrieveData } from '@/components/common/crud/CrudRetrieveContext';
import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink';
import { CrudList, ColumnDefinition } from '@/components/common/crud/CrudList';
import { EntityCrudActions } from '@/components/common/data/EntityCrudActions';

export const AccountExternalMappingsRoute: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const account = useCrudRetrieveData<Account>(DataType.Account);
  const accountId = account.id;

  const createRouteQueryParams = useMemo<QueryParams>(
    () => ({
      returnPath: location.pathname,
      m3terEntity: ExternalMappingEntityType.Account,
      m3terId: accountId,
    }),
    [accountId, location]
  );
  const { editRouteName } = useCrudRouteNames(DataType.ExternalMapping);
  const { deleteItem } = useEntityDelete(DataType.ExternalMapping);
  const crudListColumns = useMemo<Array<ColumnDefinition<ExternalMapping>>>(
    () => [
      {
        id: 'externalSystem',
        header: t('forms:labels.externalSystem'),
        accessor: 'externalSystem',
      },
      {
        id: 'externalTable',
        header: t('forms:labels.externalTable'),
        accessor: 'externalTable',
      },
      {
        id: 'externalId',
        header: t('forms:labels.externalEntityId'),
        accessor: 'externalId',
      },
      {
        id: 'actions',
        header: '',
        accessor: (externalMapping) => (
          <EntityCrudActions
            addReturnPath
            dataType={DataType.ExternalMapping}
            item={externalMapping}
            editRouteName={editRouteName}
            onDelete={deleteItem}
          />
        ),
      },
    ],
    [editRouteName, deleteItem, t]
  );
  const crudListPathParams = useMemo<PathParams>(
    () => ({
      entityType: ExternalMappingEntityType.Account,
      entityId: accountId,
    }),
    [accountId]
  );

  return (
    <Card>
      <CardActionsHeader
        actions={
          <Button
            size="sm"
            as={CrudCreateLink}
            dataType={DataType.ExternalMapping}
            queryParams={createRouteQueryParams}
          >
            {t('forms:buttons.createEntity', {
              entityName: t('common:externalMappings'),
            })}
          </Button>
        }
      >
        <Heading size="md">{t('common:externalMappings')}</Heading>
      </CardActionsHeader>
      <CardBody>
        <CrudList
          listId={OtherListIds.AccountExternalMappings}
          dataType={DataType.ExternalMapping}
          columns={crudListColumns}
          actionName="getEntityMappings"
          pathParams={crudListPathParams}
        />
      </CardBody>
    </Card>
  );
};
