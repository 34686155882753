import React from 'react';

import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
  MenuGroup,
} from '@chakra-ui/react';
import { EllipsisIcon } from 'lucide-react';

import { DataType, Id } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { IconButton } from '@m3ter-com/ui-components';

import { NamedLink } from '@/components/common/navigation/NamedLink';
import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink';
import { ExternalMappingCreateLink } from '@/components/common/navigation/ExternalMappingCreateLink';

interface AccountOverviewActionsProps {
  accountId: Id;
  onOpenIngestModal: () => void;
  onOpenEndBillingEntitiesModal: () => void;
}

export const AccountOverviewActions: React.FC<AccountOverviewActionsProps> = ({
  accountId,
  onOpenIngestModal,
  onOpenEndBillingEntitiesModal,
}) => {
  const { t } = useTranslation();

  return (
    <Menu>
      <MenuButton
        alignSelf="flex-end"
        aria-label={t('features:account.actions')}
        as={IconButton}
        icon={<EllipsisIcon />}
      />
      <MenuList>
        <MenuGroup title={t('common:account')}>
          <MenuItem onClick={onOpenIngestModal}>
            {t('features:ingest.submitUsage')}
          </MenuItem>
          <MenuItem onClick={onOpenEndBillingEntitiesModal}>
            {t('features:billing.endBillingEntities', {
              entityName: t('common:account'),
            })}
          </MenuItem>
        </MenuGroup>
        <MenuDivider />
        <MenuGroup title={t('common:commitments')}>
          <MenuItem as={CrudCreateLink} dataType={DataType.Commitment}>
            {t('forms:buttons.createEntity', {
              entityName: t('common:commitment'),
            })}
          </MenuItem>
        </MenuGroup>
        <MenuDivider />
        <MenuGroup title={t('common:balances')}>
          <MenuItem as={CrudCreateLink} dataType={DataType.Balance}>
            {t('forms:buttons.createEntity', {
              entityName: t('common:balance'),
            })}
          </MenuItem>
        </MenuGroup>
        <MenuDivider />
        <MenuGroup title={t('common:plans')}>
          <MenuItem
            addReturnPath
            as={CrudCreateLink}
            dataType={DataType.AccountPlan}
          >
            {t('forms:buttons.createEntity', {
              entityName: t('features:plans.planAttachment'),
            })}
          </MenuItem>
          <MenuItem as={NamedLink} name="create-custom-plan">
            {t('features:account.createCustomPlan')}
          </MenuItem>
          <MenuItem as={NamedLink} name="create-custom-plan-group">
            {t('features:account.createCustomPlanGroup')}
          </MenuItem>
        </MenuGroup>
        <MenuDivider />
        <MenuGroup title={t('common:contracts')}>
          <MenuItem as={CrudCreateLink} dataType={DataType.Contract}>
            {t('forms:buttons.createEntity', {
              entityName: t('common:contract'),
            })}
          </MenuItem>
        </MenuGroup>
        <MenuDivider />
        <MenuGroup title={t('common:externalMappings')}>
          <MenuItem
            as={ExternalMappingCreateLink}
            dataType={DataType.Account}
            entityId={accountId}
          />
        </MenuGroup>
        <React.Fragment>
          <MenuDivider />
          <MenuGroup title={t('common:counterAdjustments')}>
            <MenuItem as={CrudCreateLink} dataType={DataType.CounterAdjustment}>
              {t('forms:buttons.createEntity', {
                entityName: t('common:counterAdjustment'),
              })}
            </MenuItem>
          </MenuGroup>
        </React.Fragment>
      </MenuList>
    </Menu>
  );
};
