import React from 'react';

import { DataType, Id, UnsavedEntity, UserGroup } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormStack } from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormField,
  FormInput,
  FormSection,
} from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

import userGroupSchema from '@/validation/userGroup';
import {
  DataTypeFormEntityMultiSelect,
  FormEntityMultiSelect,
} from '@/components/forms/FormEntityMultiSelect';

export interface UserGroupCreateFormValues extends UnsavedEntity<UserGroup> {
  permissionPolicyIds: Array<Id>;
}

const defaultInitialValues: Partial<UserGroupCreateFormValues> = {
  permissionPolicyIds: [],
};

export interface UserGroupCreateFormProps
  extends BaseFormProps<UserGroupCreateFormValues> {}

export const UserGroupCreateForm: React.FC<UserGroupCreateFormProps> = ({
  onSave,
  onCancel,
  isSaving = false,
  initialValues = defaultInitialValues,
}) => {
  const { t } = useTranslation();

  return (
    <Form
      onSubmit={onSave}
      initialValues={initialValues}
      validationSchema={userGroupSchema}
    >
      <FormStack>
        <FormSection
          heading={t('common:entityDetails', {
            entityName: t('common:userGroup'),
          })}
        >
          <FormField
            isRequired
            name="name"
            label={t('forms:labels.name')}
            control={FormInput}
          />
          <FormField
            name="permissionPolicyIds"
            label={t('common:permissionPolicys')}
            control={
              FormEntityMultiSelect as DataTypeFormEntityMultiSelect<DataType.PermissionPolicy>
            }
            dataType={DataType.PermissionPolicy}
            accessor="name"
          />
        </FormSection>
        <FormActions
          cancelText={t('common:cancel')}
          submitText={t('forms:buttons.createEntity', {
            entityName: t('common:userGroup'),
          })}
          isSaving={isSaving}
          onCancel={onCancel}
        />
      </FormStack>
    </Form>
  );
};
