import { DataType, Id } from '@m3ter-com/m3ter-api';

import { dataTypeListQuery } from '@/queries/crud';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import useAppQuery from '@/hooks/data/useAppQuery';

const useAccountHasChildren = (accountId: Id | undefined) => {
  const pathParams = useOrgPathParams({ accountId });

  const {
    error,
    isLoading,
    data: accountChildren = [],
  } = useAppQuery(
    dataTypeListQuery(
      {
        dataType: DataType.Account,
        actionName: 'listChildren',
        pathParams,
      },
      { enabled: !!accountId }
    )
  );

  return {
    error,
    isLoading,
    hasChildren: accountChildren.length > 0,
  };
};

export default useAccountHasChildren;
