import React, { useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { Badge, SimpleGrid } from '@chakra-ui/react';

import { DataType, User } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button, Confirmation, KeyValue } from '@m3ter-com/ui-components';

import { selectUser } from '@/store/app/auth/auth';
import useDateFormatter from '@/hooks/util/useDateFormatter';
import useUserActivation from '@/hooks/features/access/useUserActivation';
import { DetailsCard } from '@/components/common/data/DetailsCard';
import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem';
import { ActiveStatusBadge } from '@/components/common/data/ActiveStatusBadge';
import { PermissionPolicyList } from '@/components/features/access/permission-policy/PermissionPolicyList';
import { UserGroupsList } from '@/components/features/access/user-group/UserGroupsList';

export interface UserDetailsProps {
  data: User;
}

export const UserDetails: React.FC<UserDetailsProps> = ({ data: user }) => {
  const { t } = useTranslation();
  const { toLongDate } = useDateFormatter();
  const { onActivateUser, onDeactivateUser, isUpdatingUserActivation } =
    useUserActivation();

  const currentUser = useSelector(selectUser);

  const isUserActive = useMemo(
    () =>
      !user.dtEndAccess || new Date(user.dtEndAccess) > new Date(Date.now()),
    [user]
  );

  const onActivateUserConfirm = useCallback(() => {
    onActivateUser(user);
  }, [onActivateUser, user]);

  const onDeactivateUserConfirm = useCallback(() => {
    onDeactivateUser(user);
  }, [onDeactivateUser, user]);

  const canDeactivateUser = user.id !== currentUser?.id;

  const fullName = `${user.firstName} ${user.lastName}`;

  return (
    <React.Fragment>
      <BreadcrumbItem title={fullName} />
      <DetailsCard
        data={user}
        dataType={DataType.User}
        header={
          <React.Fragment>
            {canDeactivateUser && isUserActive && (
              <Confirmation
                message={t('features:users.deactivateUserWarning')}
                trigger={
                  <Button
                    variant="outline"
                    intent="danger"
                    size="sm"
                    isLoading={isUpdatingUserActivation}
                  >
                    {t('features:users.deactivateUser')}
                  </Button>
                }
                onConfirm={onDeactivateUserConfirm}
              />
            )}
            {!isUserActive && (
              <Confirmation
                message={t('features:users.activateUserWarning')}
                trigger={
                  <Button
                    intent="primary"
                    size="sm"
                    isLoading={isUpdatingUserActivation}
                  >
                    {t('features:users.activateUser')}
                  </Button>
                }
                onConfirm={onActivateUserConfirm}
              />
            )}
          </React.Fragment>
        }
        details={
          <SimpleGrid columns={4} gap={6} w="100%">
            <KeyValue
              label={t('forms:labels.name')}
              value={
                <React.Fragment>
                  {fullName}
                  {user.supportUser && (
                    <Badge data-testid="support-user-badge" ml={2}>
                      {t('common:supportUser')}
                    </Badge>
                  )}
                </React.Fragment>
              }
            />
            <KeyValue
              label={t('forms:labels.emailAddress')}
              value={user.email}
            />
            <KeyValue
              label={t('forms:labels.status')}
              value={<ActiveStatusBadge ml={0} active={isUserActive} />}
            />
            <KeyValue
              label={t('forms:labels.accessEndDate')}
              value={user.dtEndAccess ? toLongDate(user.dtEndAccess) : '-'}
            />
          </SimpleGrid>
        }
      >
        <PermissionPolicyList dataType={DataType.User} id={user.id} />
        <UserGroupsList userId={user.id} />
      </DetailsCard>
    </React.Fragment>
  );
};
