import React, { useMemo } from 'react';

import { Card, CardBody, Heading, Link } from '@chakra-ui/react';

import { DataType, Id, PermissionPolicy } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  Button,
  CardActionsHeader,
  Confirmation,
} from '@m3ter-com/ui-components';

import {
  PermissionDataType,
  permissionDataTypeConfigMap,
} from '@/types/data/permissions';

import useEntityPermissionPolicyControl from '@/hooks/features/access/useEntityPermissionPolicyControl';
import useOrg from '@/hooks/data/crud/useOrg';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink';
import {
  ColumnDefinition,
  CrudList,
  CrudListFooter,
  CrudListHeader,
  CrudListTable,
} from '@/components/common/crud/CrudList';
import { EntityMultiSelectModal } from '@/components/common/data/EntitySelectModal';

interface PermissionsListProps<DT extends PermissionDataType> {
  dataType: DT;
  id?: Id;
}

const permissionSearchFields = ['name'];

const emptyArray = new Array<any>();

export const PermissionPolicyList = <DT extends PermissionDataType>({
  dataType,
  id,
}: PermissionsListProps<DT>) => {
  const { t } = useTranslation();
  const { currentOrgId: organizationId } = useOrg();

  const {
    isAddingPermissionsToEntity,
    isPermissionDisabled,
    isPermissionsModalOpen,
    isRemovingPermissionFromEntity,
    onAddPermissionsToEntity,
    onClosePermissionsModal,
    onOpenPermissionsModal,
    onRemovePermissionsFromEntity,
    selectedPermissionIds,
    setSelectedPermissionIds,
  } = useEntityPermissionPolicyControl({ dataType, id });

  const { listAction, listId } = useMemo(
    () => permissionDataTypeConfigMap[dataType],
    [dataType]
  );

  const pathParams = useMemo(
    () =>
      dataType === DataType.SupportAccess
        ? { organizationId }
        : { organizationId, id },
    [dataType, id, organizationId]
  );

  const columns = useMemo<Array<ColumnDefinition<PermissionPolicy>>>(
    () => [
      {
        id: 'name',
        header: t('forms:labels.name'),
        accessor: (permissionPolicy) => (
          <Link
            as={CrudDetailsLink}
            dataType={DataType.PermissionPolicy}
            id={permissionPolicy.id}
          >
            {permissionPolicy.name}
          </Link>
        ),
      },
      {
        id: 'managed',
        header: t('common:type'),
        accessor: (permissionPolicy) =>
          permissionPolicy.managedPolicy
            ? t('features:access.managed')
            : t('common:custom'),
      },
      {
        id: 'statements',
        header: t('forms:labels.statements'),
        accessor: (permissionPolicy) =>
          permissionPolicy.permissionPolicy.length,
      },
    ],
    [t]
  );

  const entityModalColumns = useMemo<Array<ColumnDefinition<PermissionPolicy>>>(
    () => [
      {
        id: 'name',
        header: t('forms:labels.name'),
        accessor: 'name',
      },
      {
        id: 'managed',
        header: t('common:type'),
        accessor: (item: PermissionPolicy) =>
          item.managedPolicy
            ? t('features:access.managed')
            : t('common:custom'),
      },
      {
        id: 'statements',
        header: t('forms:labels.statements'),
        accessor: (item: PermissionPolicy) => item.permissionPolicy.length,
      },
    ],
    [t]
  );

  return (
    <React.Fragment>
      <Card>
        <CardActionsHeader
          actions={
            <Button
              size="sm"
              onClick={onOpenPermissionsModal}
              isDisabled={isRemovingPermissionFromEntity}
              isLoading={isAddingPermissionsToEntity}
            >
              {t('forms:buttons.addEntity', {
                entityName: t('common:permissionPolicys'),
              })}
            </Button>
          }
        >
          <Heading size="md">{t('common:permissionPolicys')}</Heading>
        </CardActionsHeader>
        <CardBody>
          <CrudList<PermissionPolicy>
            listId={listId}
            dataType={DataType.PermissionPolicy}
            actionName={listAction}
            pathParams={pathParams}
          >
            <CrudListHeader hideCreateLink />
            <CrudListTable
              columns={columns}
              selectionType="multi"
              selectedItems={selectedPermissionIds}
              onSelectedItemsChange={setSelectedPermissionIds}
            />
            <CrudListFooter>
              <Confirmation
                trigger={
                  <Button
                    isDisabled={
                      selectedPermissionIds.length === 0 ||
                      isAddingPermissionsToEntity
                    }
                    isLoading={isRemovingPermissionFromEntity}
                  >
                    {t('common:removeSelected')}
                  </Button>
                }
                message={t('features:access.removePermissionsConfirmation', {
                  entityName: t(`common:${dataType}`),
                })}
                onConfirm={onRemovePermissionsFromEntity}
              />
            </CrudListFooter>
          </CrudList>
        </CardBody>
      </Card>
      <EntityMultiSelectModal<PermissionPolicy>
        columns={entityModalColumns}
        dataType={DataType.PermissionPolicy}
        isItemDisabled={isPermissionDisabled}
        isOpen={isPermissionsModalOpen}
        onClose={onClosePermissionsModal}
        onConfirm={onAddPermissionsToEntity}
        searchFields={permissionSearchFields}
        selected={emptyArray}
      />
    </React.Fragment>
  );
};
