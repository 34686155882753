import { combineReducers } from '@reduxjs/toolkit';

import serviceUserCreateReducer from './service-user/serviceUserCreate';
import serviceUserCredentialsReducer from './service-user/serviceUserCredentials';

const accessReducer = combineReducers({
  serviceUserCreate: serviceUserCreateReducer,
  serviceUserCredentials: serviceUserCredentialsReducer,
});

export default accessReducer;
