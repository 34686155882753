import { combineReducers } from 'redux';

import accessReducer from './access/access';
import accountsReducer from './accounts/accounts';
import analyticsReducer from './analytics/analytics';
import billingReducer from './billing/billing';
import eventsNotificationsReducer from './events-notifications/eventsNotifications';
import flagsReducer from './flags/flags';
import integrationsReducer from './integrations/integrations';
import pricingReducer from './pricing/pricing';
import usageReducer from './usage/usage';

const featuresReducer = combineReducers({
  access: accessReducer,
  accounts: accountsReducer,
  analytics: analyticsReducer,
  billing: billingReducer,
  eventsNotifications: eventsNotificationsReducer,
  flags: flagsReducer,
  integrations: integrationsReducer,
  pricing: pricingReducer,
  usage: usageReducer,
});

export type FeaturesState = ReturnType<typeof featuresReducer>;

export default featuresReducer;
