import { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import {
  create,
  DataType,
  Id,
  updateEntityPermissionPolicies,
  UserGroup,
} from '@m3ter-com/m3ter-api';

import { CrudQueryParams } from '@/types/routes';

import useAppMutation from '@/hooks/data/useAppMutation';
import useOrg from '@/hooks/data/crud/useOrg';
import useQueryString from '@/hooks/navigation/useQueryString';
import { useCrudContext } from '@/components/common/crud/CrudContext';
import { UserGroupCreateFormValues } from '@/components/features/access/user-group/UserGroupCreateForm';
import { dataCreated } from '@/store/data/data';

export const createUserGroupWithPermissionPolicies = async (
  { permissionPolicyIds, ...userGroup }: UserGroupCreateFormValues,
  organizationId: Id
): Promise<UserGroup> => {
  const createdUserGroup = await create({
    data: userGroup,
    dataType: DataType.UserGroup,
    pathParams: { organizationId },
  });

  await updateEntityPermissionPolicies(
    'addtousergroup',
    organizationId,
    permissionPolicyIds,
    { principalId: createdUserGroup.id }
  );

  return createdUserGroup;
};

const useUserGroupCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentOrgId: organizationId } = useOrg();
  const { basePath, onCancel } = useCrudContext<UserGroup>();
  const { returnPath = `${basePath}/:id` } = useQueryString<CrudQueryParams>();

  const queryClient = useQueryClient();

  const {
    error,
    isPending: isSaving,
    mutate: createUserGroup,
  } = useAppMutation({
    mutationFn: async (data: UserGroupCreateFormValues) =>
      createUserGroupWithPermissionPolicies(data, organizationId),
    onSuccess: (createdEntity) => {
      dispatch(dataCreated(DataType.UserGroup, [createdEntity]));
      queryClient.invalidateQueries({ queryKey: [DataType.UserGroup] });
      navigate(returnPath.replace(':id', createdEntity.id));
    },
  });

  const onSave = useCallback(
    (data: UserGroupCreateFormValues) => {
      createUserGroup(data);
    },
    [createUserGroup]
  );

  return {
    error,
    isSaving,
    onCancel,
    onSave,
  };
};

export default useUserGroupCreate;
